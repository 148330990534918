import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../css/RND.css';
import rndImage from '../assets/randd2.png';

function RND() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div className="randd">
      {/* Image Section */}
      <section className="image-section">
        <div className="image-container">
          <img 
            src={rndImage} 
            alt="Research and Development" 
            className="production-image" 
          />
        </div>
      </section>

      {/* R&D Content Section */}
      <section className="rnd-section">
        <div className="rnd-content" data-aos="fade-up">
          <h2>Research & Development</h2>
          <p>
            At Heramb Laboratory, innovation is the cornerstone of our success. Our dedicated R&D team explores cutting-edge advancements in chemistry, material science, and industrial processes to create sustainable and high-performing solutions for the chemical and fiber industries.
          </p>

          {/* Innovation and Expertise */}
          <h3>Innovation and Expertise</h3>
          <p>
            Our R&D division operates at the intersection of science and creativity, focusing on:
          </p>
          <ul>
            <li>
              <strong>Advanced Resins:</strong> Development of General-Purpose Resins (GPResin), Isophthalic Resins, and Vinyl Ester Resins for industrial and commercial applications.
            </li>
            <li>
              <strong>Specialty Hardening Agents:</strong> Innovation in MEKP hardeners and cobalt octoates to enhance safety, curing efficiency, and product performance.
            </li>
            <li>
              <strong>Composites and Fiberglass:</strong> Optimization of fiberglass materials, including Chopped Strand Mat (CSM) and specialized emulsion products, ensuring durability and versatility in construction and manufacturing.
            </li>
          </ul>

          {/* Quality Control and Testing */}
          <h3>Quality Control and Testing</h3>
          <p>
            Our R&D process is backed by robust quality control measures. Every product undergoes:
          </p>
          <ul>
            <li>
              Rigorous performance testing under diverse conditions.
            </li>
            <li>
              Compliance with international standards for chemical safety and environmental impact.
            </li>
            <li>
              Continuous improvement protocols to refine product formulations.
            </li>
          </ul>

          {/* Commitment to Sustainability */}
          <h3>Commitment to Sustainability</h3>
          <p>
            Environmental responsibility is at the heart of our R&D efforts. We are actively working on:
          </p>
          <ul>
            <li>
              Development of bio-based resins to reduce dependence on petroleum-derived products.
            </li>
            <li>
              Adoption of green chemistry principles to minimize hazardous waste and improve energy efficiency.
            </li>
            <li>
              Recycling and reuse initiatives for industrial by-products, reducing landfill impact.
            </li>
          </ul>

          {/* Collaboration and Global Impact */}
          <h3>Collaboration and Global Impact</h3>
          <p>
            Collaboration with industry leaders and academic institutions allows us to stay ahead of technological advancements. Key collaborations include:
          </p>
          <ul>
            <li>
              Joint research programs to develop next-generation materials.
            </li>
            <li>
              Partnerships for scaling innovative solutions globally.
            </li>
            <li>
              Knowledge exchange initiatives to foster innovation within the chemical sector.
            </li>
          </ul>

          {/* Future Aspirations */}
          <h3>Our Vision for the Future</h3>
          <p>
            Heramb Laboratory’s R&D efforts are focused on creating a sustainable and innovative future for the chemical industry. Our future roadmap includes:
          </p>
          <ul>
            <li>
              Expanding our portfolio of eco-friendly products to meet global sustainability goals.
            </li>
            <li>
              Leveraging artificial intelligence and machine learning to accelerate material discovery.
            </li>
            <li>
              Exploring nanotechnology applications in fiber and resin manufacturing for unprecedented strength and functionality.
            </li>
            <li>
              Continuing to invest in talent and infrastructure to remain at the forefront of the industry.
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default RND;

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "../css/HeaderNavbar.css";
import { FaSearch } from "react-icons/fa";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Imgrefresher from "../assets/Heramb.png";
import Image from "../assets/heramblogo.jpg";

const HeaderNavbar = () => {
    const [query, setQuery] = useState(""); // Search query state
    const [results, setResults] = useState([]); // Filtered results state
    const [menu, setMenu] = useState(false); // Sidebar visibility
    const sidebarRef = useRef(null);

    const data = [
        { id: 1, name: "Home", link: "/" },
        { id: 2, name: "About Us", link: "/about" },
        { id: 3, name: "Products", link: "/products" },
        { id: 4, name: "Contact", link: "/contact" },
        { id: 5, name: "RND", link: "/rnd" },
        { id: 6, name: "Production", link: "/production" },
        { id: 7, name: "Testing", link: "/testing" },
        { id: 8, name: "GPResin", link: "/gpresin" },
        { id: 9, name: "Clear", link: "/clear" },
        { id: 10, name: "Pet", link: "/pet" },
        { id: 11, name: "Cobalt Octoate", link: "/cobalt-octoate" },
        { id: 12, name: "Six Percent Cobalt Octoate / 6% Cobalt Octoate", link: "/six-percent-cobalt-octoate" },
        { id: 13, name: "Three Percent Cobalt Octoate / 3% Cobalt Octoate", link: "/three-percent-cobalt-octoate" },
        { id: 14, name: "Fiberglass Mart", link: "/fiberglassmart" },
        { id: 15, name: "Fiberglass CSM Mat", link: "/fiberglass-csm-mat" },
        { id: 16, name: "Chopped Strand Mat", link: "/chopped-strand-mat" },
        { id: 17, name: "CSM Mat Emulsion Cut Piece", link: "/csm-mat-emulsion-cut-piece" },
        { id: 18, name: "MEKP Hardener", link: "/mekp-hardener" },
        { id: 19, name: "Isophthalic Resins", link: "/isophthalic-resins" },
        { id: 20, name: "ISO Resin", link: "/iso-resin" },
        { id: 21, name: "Isophthalic Resin", link: "/isophthalic-resin" },
        { id: 22, name: "Fifty Percent Hydrogen Peroxide / 50% Hydrogen Peroxide", link: "/fifty-percent-hydrogen-peroxide" },
        { id: 23, name: "Vinyl Ester Resin", link: "/vinyl-ester-resin1" },
        { id: 24, name: "Mono Ethylene Glycol", link: "/mono-ethylene-glycol" },
        { id: 25, name: "Talcum Powder", link: "/talcum-powder" },
        { id: 26, name: "Hydrochloric Acid", link: "/hydrochloric-acid" },
      

    ];

    const handleSearch = (e) => {
        const value = e.target.value;
        setQuery(value);

        if (value) {
            const filteredResults = data.filter((item) =>
                item.name.toLowerCase().includes(value.toLowerCase())
            );
            setResults(filteredResults);
        } else {
            setResults([]);
        }
    };

    const menuHandler = () => {
        setMenu(!menu);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target) && !event.target.closest(".menu_icon")) {
                setMenu(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="header-navbar-container">
            {/* Header Section */}
            <header className="header">
                <div className="logo-title-container">
                    <div className="logo-container">
                        <Link to="/" onClick={() => setMenu(false)}>
                            <img src={Imgrefresher} className="logo" alt="Heramb Laboratory Logo" />
                        </Link>
                    </div>
                    <h1 className="header-title">Engineering Excellence in Fiber and Chemical Innovation</h1>
                </div>
            </header>

            {/* Navbar Section */}
            <div className="navbar_div">
                <div className="icons_container">
                    <MenuIcon onClick={menuHandler} className="menu_icon" />
                </div>

                {/* Search Bar */}
                <div className="input-wrapper">
                    <FaSearch id="search-icon" />
                    <input
                        type="text"
                        placeholder="Search here"
                        value={query}
                        onChange={handleSearch}
                    />
                </div>

                {/* Search Results */}
                {results.length > 0 && (
                    <div className="search-results">
                        {results.map((result) => (
                            <Link
                                key={result.id}
                                to={result.link}
                                className="search-result-item"
                                onClick={() => setResults([])} // Clear results on click
                            >
                                {result.name}
                            </Link>
                        ))}
                    </div>
                )}

                <div className="nav_links">
                    <Link to="/" className="nav_link">Home</Link>
                    <Link to="/about" className="nav_link">About Us</Link>
                    <Link to="/products" className="nav_link">Products</Link>
                    <Link to="/contact" className="nav_link">Contact</Link>
                </div>
            </div>

            {/* Sidebar */}
            <div ref={sidebarRef} className={`sidebar ${menu ? "show" : ""}`}>
                <div>
                    <Link to="/" onClick={() => setMenu(false)}>
                        <img src={Image} className="logoimage" alt="Heramb Laboratory Logo" />
                    </Link>
                </div>

                <Link to="/" onClick={menuHandler} className="sidebar_link">Home</Link>
                <Link to="/about" onClick={menuHandler} className="sidebar_link">About Us</Link>
                <Link to="/products" onClick={menuHandler} className="sidebar_link">Products</Link>
                <Link to="/contact" onClick={menuHandler} className="sidebar_link">Contact</Link>
            </div>
        </div>
    );
};

export default HeaderNavbar;

import React, { useState, useEffect, useRef } from 'react';
import '../css/SlideText.css';
import Image1 from '../assets/home3.png';
import Image2 from '../assets/home1.png';
import Image3 from '../assets/home4.png';

const SlideText = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  // Function to check if the section is in the viewport for mobile view
  const handleScroll = () => {
    if (window.innerWidth <= 768) { // Apply scroll-triggered animations only for mobile
      const section = sectionRef.current;
      if (section) {
        const sectionTop = section.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (sectionTop <= windowHeight * 0.7) {
          setIsVisible(true);
        }
      }
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 768) { // Add event listener only for mobile view
      window.addEventListener('scroll', handleScroll);
      handleScroll(); // Initial check on component mount

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <section
      id="slide-text-section"
      className="section-hover"
      ref={sectionRef}
      onMouseEnter={() => {
        if (window.innerWidth > 768) { // Apply hover-based animations for desktop only
          setIsVisible(true);
        }
      }}
    >
      <h3 className="slide-text-heading">Why Choose Heramb Laboratory Pvt Ltd.?</h3>
      
      <div className="slide-text-wrapper">
        <div className={`slide-item ${isVisible ? 'slide-in' : ''}`}>
          <div className={`slide-text-container ${isVisible ? 'slide-in-text' : ''}`}>
            <h3>Legacy of Trust </h3>
            <p>
              Founded in 1985, Heramb Laboratory has built a legacy of reliability, innovation, and customer satisfaction. As a second-generation company, we are proud to continue our tradition of excellence, backed by decades of expertise.
            </p>
          </div>
          <img src={Image1} alt="Legacy of Trust" className={`slide-image ${isVisible ? 'slide-in-image' : ''}`} />
        </div>

        <div className={`second-slide-item ${isVisible ? 'second-slide-in' : ''}`}>
          <div className={`second-slide-text ${isVisible ? 'second-slide-in-text' : ''}`}>
            <h3>Tailored Solutions </h3>
            <p>
               We understand that each project is unique. Our team collaborates closely with clients to develop customized solutions that meet their specific needs, whether in production, testing, or R&D.
            </p>
          </div>
          <img src={Image2} alt="Tailored Solutions" className={`second-slide-image ${isVisible ? 'second-slide-in-image' : ''}`} />
        </div>

        <div className={`slide-item ${isVisible ? 'slide-in' : ''}`}>
          <div className={`slide-text-container ${isVisible ? 'slide-in-text' : ''}`}>
            <h3>Quality Assurance </h3>
            <p>
              Our comprehensive approach to quality control ensures that every product leaving our facility meets the highest industry standards. From raw materials to finished products, quality is at the core of what we do.
            </p>
          </div>
          <img src={Image3} alt="Quality Assurance" className={`slide-image ${isVisible ? 'slide-in-image' : ''}`} />
        </div>
      </div>
    </section>
  );
};

export default SlideText;

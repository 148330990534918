import React from "react";
import ImageSlider from "./ImageSlider";
import Home_page1 from "./Home_page1";
import SlideText from "./SlideText";
import PopupBoxes from "./PopupBoxes";
import "../Home.css"; 
import { Link } from 'react-router-dom';
import OurBranches from "./OurBranches";


function Home() {
    return (
        <div className="home-container">
            {/* Section 1: Image Slider */}
            <section>
                <ImageSlider />
            </section>



            {/* Company Introduction */}
            <section className='company_section1'>
                <ul className='company_ul1'>
                    With over three decades of excellence, Heramb Laboratory Pvt Ltd. has been at the forefront of fiber molding and chemical production since 2007.
                    As a second-generation, family-owned company, we blend tradition with innovation, delivering top-tier products that meet the evolving needs of industries worldwide.
                </ul>
                <Link to={"/about"} className="about-button">Who We Are →</Link>
            </section>

            {/* Products Section */}
            <section>
                <div className='products-section'>
                    <div className='products-container'>
                        <h2 className='products-title'>Our Products</h2>
                        <p className='products-description'>
                            Discover our range of innovative products, crafted with precision and care to meet the diverse needs of our clients across industries. From advanced chemical formulations to high-quality fiber molds, we ensure excellence in every product.
                        </p>
                        <Link to={"/products"} className="products-button">Explore Products →</Link>
                    </div>
                </div>
            </section>


            {/* Other Sections */}
            <section>
                <Home_page1 />
            </section>
            <section>
                <SlideText />
            </section>
            <section>
                <PopupBoxes />
            </section>

            <section>
                <OurBranches />
            </section>


        </div>
    );
};



export default Home;


import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import RND from "./pages/RND";
import Testing from "./pages/Testing";
import Production from "./pages/Production";
import PopupBoxes from './pages/PopupBoxes';
import Products from './pages/Products';
import Rewards from './pages/Rewards';
import Contact1 from './pages/Contact1';
import GPResin from './products/GPResin';
import Clear from './products/Clear';
import Pet from './products/Pet';
import SixPercentCobaltOctoate from './products/SixPercentCobaltOctoate';
import ThreePercentCobaltOctoate from './products/ThreePercentCobaltOctoate';
import CobaltOctoate from './products/CobaltOctoate';
import FiberglassCSMMat from './products/FiberglassCSMMat';
import ChoppedStrandMat from './products/ChoppedStrandMat';
import CSMMatEmulsionCutPiece from './products/CSMMatEmulsionCutPiece';
import FiberglassMart from './products/FiberglassMat';
import MEKPHardener from './products/MEKPHardener';
import ISOResin from './products/ISOResin';
import IsophthalicResins from './products/IsophthalicResins';
import IsophthalicResin from './products/IsophthalicResin';
import FiftyPercentHydrogenPeroxide from './products/FiftyPercentHydrogenPeroxide';
import VinylEsterResin1 from './products/VinylEsterResin1';
import MonoEthyleneGlycol from './products/MonoEthyleneGlycol';
import TalcumPowder from './products/TalcumPowder';
import HydrochloricAcid from './products/HydrochloricAcid';
import HeaderNavbar from './components/HeaderNavbar';


function App() {
  return (
    <Router>
      <div className="App">
      {/* <Header />
        <Navbar /> */}
        <HeaderNavbar />
        
        {/* Content goes here */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact1 />} />
          <Route path="/rnd" element={<RND />} />
        <Route path="/testing" element={<Testing />} />
        <Route path="/production" element={<Production />} />
        <Route path="/products" element={<Products />} />
        <Route path="/rewards" element={<Rewards />} />

        <Route path="/gpresin" element={<GPResin />} />
        <Route path="/clear" element={<Clear />} />
        <Route path="/pet" element={<Pet />} />
  
        <Route path="/cobalt-octoate" element={<CobaltOctoate />} />
        <Route path="/six-percent-cobalt-octoate" element={<SixPercentCobaltOctoate />} />
        <Route path="/three-percent-cobalt-octoate" element={<ThreePercentCobaltOctoate />} />
        
        <Route path="/fiberglassmart" element={<FiberglassMart />} />
        <Route path="/fiberglass-csm-mat" element={<FiberglassCSMMat />} />
        <Route path="/chopped-strand-mat" element={<ChoppedStrandMat />} />
        <Route path="/csm-mat-emulsion-cut-piece" element={<CSMMatEmulsionCutPiece />} />
              
        <Route path="/mekp-hardener" element={<MEKPHardener />} />
      
        <Route path="/isophthalic-resins" element={<IsophthalicResins />} />
        <Route path="/iso-resin" element={<ISOResin />} />
        <Route path="/isophthalic-resin" element={<IsophthalicResin />} />
        
        <Route path="/fifty-percent-hydrogen-peroxide" element={<FiftyPercentHydrogenPeroxide />} />
           
        <Route path="/vinyl-ester-resin1" element={<VinylEsterResin1 />} />
        
        <Route path="/mono-ethylene-glycol" element={<MonoEthyleneGlycol />} />

        <Route path="/talcum-powder" element={<TalcumPowder />} />
        
        <Route path="/hydrochloric-acid" element={<HydrochloricAcid />} />

        </Routes>
        
        
        {/* <PopupBoxes /> */}
        {/* Footer should be inside the main container */}
        <Footer />
      </div>
      
    </Router>
  );
};

export default App;

import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../css/About.css";
import MissionVision from './MissionVision';
import AboutImage from '../assets/about4.png'; // Add your image here
import TeamMembers from './TeamMembers';
import Home_page1 from './Home_page1';
import OurBranches from './OurBranches';


function About() {
    useEffect(() => {
        AOS.init({ duration: 1200 });
    }, []);

    return (
        <div className='about_page'>
            {/* Part 1: Image Section */}
            <section className="image-section">
                <div className="image-container">
                    <img src={AboutImage} alt="Heramb Laboratory" className="about-image" />
                </div>
            </section>

            

            {/* Part 2: About Us Section */}
            <section className="about-section">
               <div>
                <div>
                <div className="about-content" data-aos="fade-up">
                    <h1>About Us</h1>
                    <p className="lead-text">
                        Heramb Laboratory Pvt. Ltd. has been a leader in the chemical industry for over a decade, providing innovative solutions to clients worldwide.
                    </p>
                </div>

                

                <div className="journey-section" data-aos="fade-up">
                    <h2>Our Journey</h2>
                    <p>
                        Since our inception in 1985, Heramb Laboratory Pvt Ltd. has evolved from a small-scale operation into a global leader in fiber molding and chemical manufacturing. With a commitment to continuous improvement and innovation, we have expanded our capabilities, serving clients across diverse industries.
                    </p>
                    <p>
                        Today, as a second-generation company, we continue to uphold our founding principles while embracing the future with new technologies and solutions. Our team’s dedication to excellence, innovation, and customer satisfaction is what drives our success.
                    </p>
                </div>
                </div>

                <div className="image1">
                    <img src={AboutImage} alt="Heramb Laboratory" className="image2" />
                </div>
                </div>
            </section>




       
        <section className="core-values-section" data-aos="fade-up">
                <h2>Our Core Values</h2>
                <ul className="core-values-list">
                    <li>Innovation and Excellence</li>
                    <li>Customer Satisfaction</li>
                    <li>Integrity and Trust</li>
                    <li>Environmental Responsibility</li>
                </ul>
            </section>



            <section className="why-choose-us-section" data-aos="fade-up">
                <h2>Why Choose Us</h2>
                <div className="why-choose-us-content">
                    <div className="why-box">
                        <h3>Customer Focus</h3>
                        <p>
                            We prioritize our clients' needs, delivering tailor-made solutions that exceed expectations.
                        </p>
                    </div>
                    <div className="why-box">
                        <h3>Advanced Technology</h3>
                        <p>
                            Utilizing state-of-the-art equipment and methodologies to ensure superior quality in every product.
                        </p>
                    </div>
                    <div className="why-box">
                        <h3>Sustainability</h3>
                        <p>
                            Committed to environmentally responsible practices, reducing our carbon footprint, and promoting green solutions.
                        </p>
                    </div>
                    <div className="why-box">
                        <h3>Global Presence</h3>
                        <p>
                            Trusted by clients worldwide, we continue to expand our reach and serve diverse industries.
                        </p>
                    </div>
                </div>
            </section>


           
            <section className="mission-vision-section">
                <MissionVision />
            </section>

            <section >
                <TeamMembers />
            </section>

            <section>
                <OurBranches />
            </section>
        </div>
    );
};

export default About;

import React from 'react';
import '../css/ProductList.css'; 
import image2 from '../assets/cobaltoctoate.jpg';
import image3 from '../assets/fiberglass.jpg';
import image5 from '../assets/hydrochloricacid.jpg';
import gpimage from '../assets/gpresin.jpg';
import { Link } from 'react-router-dom';
import vinylimage from '../assets/vinylesterresinsecond.jpg';
import isophthalicimg from '../assets/isophthalicresin.jpg';
import mekp from '../assets/mekphardener.jpg';
import hydrogenperoxide from '../assets/hydrogenperoxide.jpg';
import talcumpowder from '../assets/talcumpowder.jpg';
import monoeg from '../assets/monoeg.jpg'

const products = [
  {
    name: 'GP Resin',
    imgSrc: gpimage,
    url: '/gpresin'
  },
  {
    name: 'Cobalt Octoate',
    imgSrc: image2,
    url: '/cobalt-octoate'
  },
  {
    name: 'Fiberglass Mat',
    imgSrc: image3,
    url: '/fiberglassmart'
  },

  {
    name: 'Methyl Ethyl Ketone Peroxide Hardener',
    imgSrc: mekp,
    url: '/mekp-hardener'
  },
  {
    name: 'Isophthalic Resins',
    imgSrc: isophthalicimg,  
    url: '/isophthalic-resins'
  },

  {
    name: '50 % Hydrogen Peroxide',
    imgSrc: hydrogenperoxide,
    url: '/fifty-percent-hydrogen-peroxide'
  },

  {
    name: 'Vinyl Ester Resin',
    imgSrc: vinylimage,  
    url: '/vinyl-ester-resin1'
  },
  {
    name: 'Mono Ethylene Glycol',
    imgSrc: monoeg,
    url: '/mono-ethylene-glycol'
  },
 
  {
    name: 'Talcum Powder',
    imgSrc: talcumpowder,
    url: '/talcum-powder'
  },
  {
    name: 'Hydrochloric Acid',
    imgSrc: image5,
    url: '/hydrochloric-acid'
  },
];

const ProductList = () => {
  return (
    <div className="new-products-section">
      <h2 className="new-products-title">Our Products</h2>
      <div className="new-products-container">
        {products.map((product, index) => (
          <div className="new-product-card" key={index}>
            <div className="new-product-image-container">
              <img src={product.imgSrc} alt={product.name} className="new-product-image" />
            </div>
            <h3 className="new-product-name">{product.name}</h3>
            <Link to={product.url} className="new-product-link">
                            View Product
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductList;

import React from 'react';
import "../css/OurBranches.css";


const OurBranches = () => {
  return (
    <div>
       <section className="branches-section-slideshow">
                <h2 className="branches-title-slideshow">Our Branches</h2>
                <div className="branches-marquee">
                    <div className="branches-track">
                        {[
                            "Satara",
                            "Kolhapur",
                            "Pune",
                            "Nagar",
                            "Indapur",
                            "Hubali (Karnataka)",
                            "Bangalore",
                            "Mumbai",
                            "Bhivandi",
                            "Hyderabad",
                        ].map((branch, index) => (
                            <div key={index} className="branch-item">
                                {branch}
                            </div>
                        ))}
                    </div>
                </div>
            </section>

    </div>
  )
}

export default OurBranches

import React from 'react';
import "../css/Footer.css";
import indiamartLogo from '../assets/INDIAMART.png';
// Import Font Awesome for icons
import { FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';


function Footer() {
  return (
    <footer>
      <div id="footer_section">
        {/* Head Office Section */}
        <div className="footer-section">
          <h2>Head Office</h2>
          <ul>
            <li className='footer-font'>
              <a href="https://www.google.com/maps/place/S+V+TRADING+CO./@18.4623178,73.921628,786m/data=!3m2!1e3!4b1!4m6!3m5!1s0x3bc2ea2335304ef3:0x84a7a8a7dd73718b!8m2!3d18.4623127!4d73.9242029!16s%2Fg%2F11c2kgvg0q?entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noopener noreferrer">
                <i className="fas fa-map-marker-alt"></i> Heramb Laboratories Pvt. Ltd. <br />
                37/297 Maharshi Nagar, Parvati, <br />
                Pune-411009, Maharashtra, India.
              </a>
            </li>
            <li className='footer-font'>
              <a href="tel:+919822912970">
                <i className="fas fa-phone-alt"></i> Phone: +91 98229 12970 / <br />
              </a>
              <a href="tel:+919890911136">
                +91 9890911136 <br />
              </a>
            </li>
            <li className='footer-font'>
              <a href="mailto:heramblaboraty@gmail.com">
                <i className="fas fa-envelope"></i> Email: heramblaboraty@gmail.com
              </a>
            </li>
            <li>
                   {/* WhatsApp Icon */}
            <a 
              href="https://wa.me/919822912970?text=Hello%20V.%20Yadav,%20I%20hope%20this%20message%20finds%20you%20well.%20I%20have%20some%20queries%20and%20would%20appreciate%20it%20if%20you%20could%20clarify%20them.%20Thank%20you."
              target="_blank" 
              rel="noopener noreferrer" 
              className="whatsapp-link"
            >
              <FaWhatsapp className='whatsapp-logo' size={32} color="green" />
              <span className="whatsapp-text">WhatsApp</span>
            </a>
            </li>
          </ul>
        </div>

        {/* Products Section */}
        <div className="footer-section">
       <h2> <Link to={"/products"} className="new-product-link"> Products  </Link></h2>
          <ul className='footer-font'>
          <li> <Link to={"/gpresin"} >  GP Resin </Link></li>
          <li> <Link to={"/cobalt-octoate"} >  Cobalt Octoate </Link></li>
          <li> <Link to={"/fiberglassmart"} >  Fiberglass </Link></li>
          <li> <Link to={"/fifty-percent-hydrogen-peroxide"} >  50 % Hydrogen Peroxide </Link></li>
          <li> <Link to={"/mekp-hardener"} >  Methyl Ethyl Ketone Peroxide Hardener </Link></li>
          <li> <Link to={"/isophthalic-resins"} >  Isophthalic Resins </Link></li>
          <li> <Link to={"/products"} >  All Products </Link></li>
          </ul>
        </div>

        {/* Quick Links Section */}
        <div className="footer-section">
          <h2>Quick Links</h2>
          <ul className='footer-font'>
          <li> <Link to={"/about"} >  About Us </Link></li>
          <li> <Link to={"/contact"} >  Contact Us </Link></li>
          <li> <Link to={"/products"} >  Products </Link></li>
          <li> <Link to={"/production"} >  Manufacturing / Production </Link></li>
          <li> <Link to={"/testing"} > Testing </Link></li>
          <li> <Link to={"/rnd"} >  R & D </Link></li>
          {/* <li> <Link to={"/rewards"} >  Rewards and Recognition </Link></li> */}

            
          </ul>
        </div>

        {/* Social Media Section */}
        <div className="footer-section">
          <h2>Follow Us</h2>
          <div className="social-icons">
            <a href="https://www.indiamart.com/heramblaboratories-pune/profile.html" target="_blank" rel="noopener noreferrer">
              <img src={indiamartLogo} alt="Indiamart" className="indiamart-logo" />
              <span className="indiamart-text">Indiamart</span>
            </a>
          </div>
        </div>
      </div>

      {/* Copyright Line */}
      <div className="footer-copyright">
        <p>© 2024 Heramb Laboratories Pvt. Ltd. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
